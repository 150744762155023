import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Isvg from "react-inlinesvg";
import Page from "../containers/page";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { API_ENDPOINT, SITE_URL } from "../constants";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import Img from '../components/Img';


import ContactForm from "../components/forms/contactForm";
import AOS from "aos";
import "aos/dist/aos.css";
import CuroflowIcon from "../assets/images/Curoflow_Digital_Telemedicine_Platform_Icon.png";
import SupportForm from "../components/forms/supportForm3";

var striptags = require("striptags");

function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

class OurSolutions extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.player = React.createRef();

    this.state = {
      promotedProjects: [],
      poolActive: false,
      chatForm: false,
      yScroll2: 0,
      ourSolutions: [],
      solutionsStatus: false,
      pageData: null,
      ...props.initialData,
    };
  }

  listenToScroll = () => {
    const yScroll2 =
      document.body.scrollTop || document.documentElement.scrollTop;

    this.setState({
      yScroll2: yScroll2,
    });
  };

  sendMessage1 = (data) => {
    if (data.checked) {
      data.lang = this.props.lang;
      this.setState(
        {
          loading1: true,
        },
        () => {
          fetch(API_ENDPOINT + "/data/contact/new", {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ data }),
          })
            .then(parseJSON)
            .then(({ result, status }) => {
              if (result.error) {
                this.setState({ error: result.error });
              } else {
                this.setState(
                  {
                    supportForm: false,
                  },
                  () => {
                    const path = "thank-you";
                    this.props[0].history.push(`/${path}`);
                  }
                );
              }
            });
        }
      );
    }
  };





  sendMessage2 = (data) => {
    this.props.setRecaptchaCallback(() => {

      if (data && data.checked && this.props.allowSubmit) {
        if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
          return this.setState({ contactFormError: 'Please fill in your work email' });
        }
        else this.setState({ contactFormError: null })

        // if (data.message?.length < 25) return this.setState({ contactFormMessageError: 'Please use at least 25 letters' });
        // else this.setState({ contactFormMessageError: null })

        data.lang = this.props.lang;
        data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
        data.bookADemo = false
        data.page = this.props?.[0]?.match?.params?.alias?.replace(/-/g, ' ') || 'No Data';
        data.token = this.props.recaptchaToken
        data.isMobile = this.props.isMobile
        data.osMobileNameUA = this.props.osMobileNameUA
        data.browserName = this.props.browserName,
          data.browserNameUA = this.props.browserNameUA

        this.setState(
          {
            loading2: true,
          },
          () => {
            setTimeout(() => {
              this.player.current.pause();
            }, 1120);

            fetch(API_ENDPOINT + "/data/contact/new", {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({ data }),
            })
              .then(parseJSON)
              .then(({ result, status }) => {
                if (status === 429) {
                  this.setState({
                    solutionsStatus: true,
                    loading2: false,
                  });
                }
                if (result.error) {
                  this.setState({ error: result.error });
                } else {
                  this.props.bookDemo(false);

                  const path = "thank-you";
                  this.props[0].history.push(`/${path}`);
                }
              });
          }
        );
      }
    })
    this.props.updateToken();
  }




  get = () => {
    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }


  };

  componentDidMount() {
    this.get();

    if (this.props[0].location && !this.props[0].location.hash) {
      if (typeof window !== "undefined") {
        window.scrollTo(0, 0);
      }
    }

    this.updateWindowDimensions();
    window.addEventListener("scroll", this.listenToScroll);

    AOS.init({
      duration: 1000,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps[0].location.pathname != this.props[0].location.pathname) {
      this.get();

      if (this.props[0].location.hash) {
        window.location.reload(false)
      }
    }

    if (prevProps[0].location.hash != this.props[0].location.hash && this.props[0].location.hash) {
      let id = this.props[0].location.hash.replace("#", "");
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: "smooth", block: 'start' });
        history.pushState("", document.title, window.location.pathname + window.location.search);
      }

    }

    if (prevProps[0].location.hash != this.props[0].location.hash && !this.props[0].location.hash) {
      window.scrollTo(0, 0)

    }

    if (this.state.pageData && JSON.stringify(this.state.pageData) !== JSON.stringify(prevState.pageData) && this.props[0].location && this.props[0].location.hash) {
      let id = this.props[0].location.hash.replace("#", "");
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({ behavior: "smooth", block: 'start' });
        history.pushState("", document.title, window.location.pathname + window.location.search);
      }
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    console.log(this.state.pageData);
    return (
      <div>
        {this.state.supportForm ? (
          <div
            className={
              this.state.supportForm ? "overlay overlay-active" : "overlay"
            }
            onClick={() => {
              this.setState({
                supportForm: false,
              });
            }}
          ></div>
        ) : null}


        <div
          className={
            this.props.openModalBookDemo
              ? "contact-popup open"
              : "contact-popup"
          }
        >
          <span
            className="close-icon"
            onClick={() => {
              this.props.bookDemo(false);
            }}
          >
            x
          </span>
          {!this.state.loading3 && !this.state.supportStatus ? (
            <SupportForm initialValues={{ dob: '1' }} onSubmit={this.sendMessage2} lang={this.props.lang} />
          ) : null}
          {this.state.loading3 ? (
            <Player
              ref={this.player}
              autoplay={true}
              onEvent={(event) => {
                if (event == "complete") {
                  this.setState({
                    supportForm: false,
                    loading3: false,
                  });
                }
              }}
              //keepLastFrame={true}
              //loop={false}
              controls={true}
              src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
              style={{ height: "300px", width: "300px" }}
            ></Player>
          ) : null}
          {this.state.supportStatus && (
            <h2 className="status-popup support-status">
              {"Please, try again later!".translate(this.props.lang)}
            </h2>
          )}
        </div>
        {this.state.pageData ? (
          <div>
            {this.state.pageData.section1 ? (
              <section
                className={`sectionTextBigimg ${this.state.pageData &&
                  this.state.pageData.section[0] &&
                  this.state.pageData.section[0].backgroundImage
                  ? ""
                  : "sectionTextBigImgNoBg curoflowBlack"
                  }`}
              >


                {this.state.pageData &&
                  this.state.pageData.section[0] &&
                  this.state.pageData.section[0].topImg
                  ? <Isvg
                    src={
                      API_ENDPOINT +
                      (this.state.pageData &&
                        this.state.pageData.section[0] &&
                        this.state.pageData.section[0].topImg)
                    } className="topImgTechnology"
                  /> : ""}



                <div className="title">
                  {/* <h1 dangerouslySetInnerHTML={{__html: Object.translate(this.state.pageData && this.state.pageData.section[0], 'title', this.props.lang)}}></h1> */}
                  <h1>
                    {this.state.pageData &&
                      this.state.pageData.section[0] &&
                      this.state.pageData.section[0].title[this.props.lang]}
                  </h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: Object.translate(
                        this.state.pageData && this.state.pageData.section[0],
                        "shortDescription",
                        this.props.lang
                      ),
                    }}
                  ></div>
                </div>
                <div className="container-curoflow">
                  {this.state.pageData &&
                    this.state.pageData.section[0] &&
                    this.state.pageData.section[0].backgroundImage ? (
                    <Img src={API_ENDPOINT + (this.state.pageData?.section?.[0]?.backgroundImage?.[this.props.lang])} alt={(this.state.pageData?.section?.[0]?.backgroundImage?.[this.props.lang]?.replace('/uploads/', '')?.replace(/_/g, ' ')?.slice(0, -4))}
                    />
                  ) : null}
                </div>
                <img
                  src={CuroflowIcon}
                  className={`${this.state.pageData &&
                    this.state.pageData.backgroundImagePositionRight
                    ? "rightSideOurSolutons"
                    : " "
                    } ${this.state.pageData &&
                      this.state.pageData.backgroundImagePositionLeft
                      ? "leftSideOurSolutons"
                      : " "
                    }`}
                  alt="Curoflow Digital Telemedicine Platform Icon"
                />
              </section>
            ) : null}




            {this.state.pageData.section2 ? (
              <section
                className={`curoflow-section curoflow-paddiing curoflow-headings ${this.state.pageData &&
                  this.state.pageData.section[1] &&
                  this.state.pageData.section[1].item[0] &&
                  this.state.pageData.section[1].item[0].backgroundLightGrey
                  ? "curoflow-bg-lightgery"
                  : this.state.pageData &&
                    this.state.pageData.section[1] &&
                    this.state.pageData.section[1].item[0] &&
                    this.state.pageData.section[1].item[0].backgroundGrey
                    ? "curoflow-bg-grey"
                    : ""
                  }`}
              >
                <div className="container-curoflow column-reverse">
                  <div className="col-curoflow-lg-6">
                    <h2>
                      {this.state.pageData &&
                        this.state.pageData.section[1] &&
                        this.state.pageData.section[1].item[0]?.title[
                        this.props.lang
                        ]}{" "}
                    </h2>
                    <div className="content-ourS"
                      dangerouslySetInnerHTML={{
                        __html: Object.translate(
                          this.state.pageData &&
                          this.state.pageData.section[1] &&
                          this.state.pageData.section[1].item[0],
                          "shortDescription",
                          this.props.lang
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="col-curoflow-lg-6">
                    <Img
                      src={
                        API_ENDPOINT +
                        (this.state.pageData &&
                          this.state.pageData.section[1] &&
                          this.state.pageData.section[1].item[0] &&
                          this.state.pageData.section[1].item[0].image?.[this.props.lang])
                      }
                      alt={
                        this.state.pageData &&
                        this.state.pageData.section[1] &&
                        this.state.pageData.section[1].item[0] &&
                        this.state.pageData.section[1].item[0].image
                          ?.[this.props.lang]?.replace("/uploads/", "")
                          .replace("_", " ")
                          .slice(0, -4)
                      }
                    />

                  </div>
                </div>
              </section>
            ) : null}








            {this.state.pageData.section10 && this.props.lang === 'en' ? (
              <section
                className={`curoflow-section curoflow-paddiing curoflow-headings curoflow-newT`}
              >
                <div className="container-curoflow">
                  <div className="col-curoflow-lg-6">
                    <a href={`${SITE_URL}/en/integrations/Semble-practice-management-system`} >
                      <Img
                        src={
                          API_ENDPOINT +
                          (this.state.pageData &&
                            this.state.pageData.section[9] &&
                            this.state.pageData.section[9].item[0] &&
                            this.state.pageData.section[9].item[0].image)
                        }
                        alt={
                          this.state.pageData &&
                          this.state.pageData.section[1] &&
                          this.state.pageData.section[1].item[0] &&
                          this.state.pageData.section[1].item[0].image
                            ?.[this.props.lang]?.replace("/uploads/", "")
                            .replace("_", " ")
                            .slice(0, -4)
                        }
                      />
                    </a>

                  </div>
                  <div className="col-curoflow-lg-6">
                    <h2>
                      {this.state.pageData &&
                        this.state.pageData.section[9] &&
                        this.state.pageData.section[9].item[0]?.title[
                        this.props.lang
                        ]}{" "}
                    </h2>
                    <div className="content-ourS"
                      dangerouslySetInnerHTML={{
                        __html: Object.translate(
                          this.state.pageData &&
                          this.state.pageData.section[9] &&
                          this.state.pageData.section[9].item[0],
                          "shortDescription",
                          this.props.lang
                        ),
                      }}
                    ></div>
                    <a href={this.state.pageData &&
                      this.state.pageData.section[9] &&
                      this.state.pageData.section[9].item[0]?.linkBtn[
                      this.props.lang
                      ]}>
                          <button>{this.state.pageData &&
                      this.state.pageData.section[9] &&
                      this.state.pageData.section[9].item[0]?.textBtn[
                      this.props.lang
                      ]}</button>


                    </a>
                  </div>

                </div>
              </section>
            ) : null}


























            {this.state.pageData.section3 ? (
              <section className={`curoflow-section curoflow-margin-bottom ${this.state.pageData &&
                this.state.pageData.section[2] &&
                this.state.pageData.section[2].item[0] &&
                this.state.pageData.section[2].item[0].backgroundLightGrey
                ? "curoflow-bg-lightgery"
                : this.state.pageData &&
                  this.state.pageData.section[2] &&
                  this.state.pageData.section[2].item[0] &&
                  this.state.pageData.section[2].item[0].backgroundGrey
                  ? "curoflow-bg-grey"
                  : ""
                }`}>
                <div className="container-curoflow">
                  <div className="col-curoflow-lg-6 ">
                    <Img className="margin-bottom2"
                      src={
                        API_ENDPOINT +
                        (this.state.pageData &&
                          this.state.pageData.section[2] &&
                          this.state.pageData.section[2].item[0] &&
                          this.state.pageData.section[2].item[0].image)
                      }

                      alt={
                        this.state.pageData &&
                        this.state.pageData.section[2] &&
                        this.state.pageData.section[2].item[0] &&
                        this.state.pageData.section[2].item[0].image
                          ?.replace("/uploads/", "")
                          .replace("_", " ")
                          .slice(0, -4)
                      }
                    />
                  </div>
                  <div className="col-curoflow-lg-6">
                    <h2>
                      {this.state.pageData &&
                        this.state.pageData.section[2] &&
                        this.state.pageData.section[2].item[0]?.title[
                        this.props.lang
                        ]}{" "}
                    </h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: Object.translate(
                          this.state.pageData &&
                          this.state.pageData.section[2] &&
                          this.state.pageData.section[2].item[0],
                          "shortDescription",
                          this.props.lang
                        ),
                      }}
                    ></p>
                  </div>
                </div>
              </section>
            ) : null}


            {this.state.pageData.section8 ? (
              <section className="sectionPatinent">
                <div className="container-curoflow column-reverse">
                  <div className="col-curoflow-lg-6">
                    <h2>
                      {this.state.pageData &&
                        this.state.pageData.section[7] &&
                        this.state.pageData.section[7].item[0]?.title[
                        this.props.lang
                        ]}
                    </h2>
                    <div className="content-ourS"
                      dangerouslySetInnerHTML={{
                        __html: Object.translate(
                          this.state.pageData &&
                          this.state.pageData.section[7] &&
                          this.state.pageData.section[7].item[0],
                          "shortDescription",
                          this.props.lang
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="col-curoflow-lg-6">
                    <Img
                      src={
                        API_ENDPOINT +
                        (this.state.pageData &&
                          this.state.pageData.section[7] &&
                          this.state.pageData.section[7].item[0] &&
                          this.state.pageData.section[7].item[0].image)
                      }
                      alt={
                        this.state.pageData &&
                        this.state.pageData.section[7] &&
                        this.state.pageData.section[7].item[0] &&
                        this.state.pageData.section[7].item[0].image
                          ?.replace("/uploads/", "")
                          .replace("_", " ")
                          .slice(0, -4)
                      }
                    />

                  </div>
                </div>
              </section>
            ) : null}



            {this.state.pageData.section9 ? (
              <section className="sectionPatinent">
                <div className="container-curoflow">
                  <div className="col-curoflow-lg-6">
                    <Img
                      src={
                        API_ENDPOINT +
                        (this.state.pageData &&
                          this.state.pageData.section[8] &&
                          this.state.pageData.section[8].item[0] &&
                          this.state.pageData.section[8].item[0].image)
                      }
                      alt={
                        this.state.pageData &&
                        this.state.pageData.section[8] &&
                        this.state.pageData.section[8].item[0] &&
                        this.state.pageData.section[8].item[0].image
                          ?.replace("/uploads/", "")
                          .replace("_", " ")
                          .slice(0, -4)
                      }
                    />

                  </div>
                  <div className="col-curoflow-lg-6">
                    <h2>
                      {this.state.pageData &&
                        this.state.pageData.section[8] &&
                        this.state.pageData.section[8].item[0]?.title[
                        this.props.lang
                        ]}
                    </h2>
                    <div className="content-ourS"
                      dangerouslySetInnerHTML={{
                        __html: Object.translate(
                          this.state.pageData &&
                          this.state.pageData.section[8] &&
                          this.state.pageData.section[8].item[0],
                          "shortDescription",
                          this.props.lang
                        ),
                      }}
                    ></div>

                  </div>
                </div>
              </section>
            ) : null}

            {this.state.pageData.section4 ? (
              <section className="curoflow-section-info">
                <div className="container-curoflow">
                  <div className="col-curoflow-lg-4 bg-white">
                    <div className="info-box">
                      <Isvg
                        src={
                          API_ENDPOINT +
                          (this.state.pageData &&
                            this.state.pageData.section[3] &&
                            this.state.pageData.section[3].item[0] &&
                            this.state.pageData.section[3].item[0].image)
                        }
                      />
                      <h4 className="black-title">
                        {this.state.pageData &&
                          this.state.pageData.section[3] &&
                          this.state.pageData.section[3].item[0]?.title[
                          this.props.lang
                          ]}{" "}
                      </h4>
                      <div
                        className="black-text"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[3] &&
                            this.state.pageData.section[3].item[0],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-curoflow-lg-4 bg-white">
                    <div className="info-box">
                      <Isvg
                        src={
                          API_ENDPOINT +
                          (this.state.pageData &&
                            this.state.pageData.section[3] &&
                            this.state.pageData.section[3].item[1] &&
                            this.state.pageData.section[3].item[1].image)
                        }
                      />
                      <h4 className="black-title">
                        {this.state.pageData &&
                          this.state.pageData.section[3] &&
                          this.state.pageData.section[3].item[1]?.title[
                          this.props.lang
                          ]}{" "}
                      </h4>
                      <div
                        className="black-text"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[3] &&
                            this.state.pageData.section[3].item[1],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="col-curoflow-lg-4 bg-white">
                    <div className="info-box">
                      <Isvg
                        src={
                          API_ENDPOINT +
                          (this.state.pageData &&
                            this.state.pageData.section[3] &&
                            this.state.pageData.section[3].item[2] &&
                            this.state.pageData.section[3].item[2].image)
                        }
                      />
                      <h4 className="black-title">
                        {this.state.pageData &&
                          this.state.pageData.section[3] &&
                          this.state.pageData.section[3].item[2]?.title[
                          this.props.lang
                          ]}{" "}
                      </h4>
                      <div
                        className="black-text"
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[3] &&
                            this.state.pageData.section[3].item[2],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}










            {this.state.pageData.section5 ? (
              <div id={"section4"} className="curoflow-margin-top">
                <section className="curoflow-section-info curoflow-section-box grey-c patientContent">
                  <div className="sectionTextBigimg sectionText">
                    <div className="title">
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[4] &&
                            this.state.pageData.section[4].item[0],
                            "title",
                            this.props.lang
                          ),
                        }}
                      ></h1>
                      <h4
                        dangerouslySetInnerHTML={{
                          __html: Object.translate(
                            this.state.pageData &&
                            this.state.pageData.section[4] &&
                            this.state.pageData.section[4].item[0],
                            "shortDescription",
                            this.props.lang
                          ),
                        }}
                      ></h4>
                    </div>
                  </div>
                  <div className="margin-bottom-c patientContentC">
                    <div className="container-curoflow ">
                      <div className="col-curoflow-lg-6 ">
                        <Img
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[1] &&
                              this.state.pageData.section[4].item[1].image)
                          }
                          className="img-margin"
                          alt={
                            this.state.pageData &&
                            this.state.pageData.section[4] &&
                            this.state.pageData.section[4].item[1] &&
                            this.state.pageData.section[4].item[1].image
                              ?.replace("/uploads/", "")
                              .replace("_", " ")
                              .slice(0, -4)
                          }
                        />
                      </div>
                      <div className="col-curoflow-lg-6">
                        <h2>
                          {this.state.pageData &&
                            this.state.pageData.section[4] &&
                            this.state.pageData.section[4].item[1]?.title[
                            this.props.lang
                            ]}{" "}
                        </h2>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[1],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className="margin-bottom-c1">
                    <div className="container-curoflow  ">
                      <div className="col-curoflow-lg-4 bg-white">
                        <div className="info-box">
                          <Isvg
                            src={
                              API_ENDPOINT +
                              (this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[2] &&
                                this.state.pageData.section[4].item[2].image)
                            }
                          />
                          <h4 className="black-title">
                            {this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[2]?.title[
                              this.props.lang
                              ]}{" "}
                          </h4>
                          <div
                            className="black-text"
                            dangerouslySetInnerHTML={{
                              __html: Object.translate(
                                this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[2],
                                "shortDescription",
                                this.props.lang
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-curoflow-lg-4 bg-white">
                        <div className="info-box">
                          <Isvg
                            src={
                              API_ENDPOINT +
                              (this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[3] &&
                                this.state.pageData.section[4].item[3].image)
                            }
                          />
                          <h4 className="black-title">
                            {this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[3]?.title[
                              this.props.lang
                              ]}{" "}
                          </h4>
                          <div
                            className="black-text"
                            dangerouslySetInnerHTML={{
                              __html: Object.translate(
                                this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[3],
                                "shortDescription",
                                this.props.lang
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-curoflow-lg-4 bg-white">
                        <div className="info-box">
                          <Isvg
                            src={
                              API_ENDPOINT +
                              (this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[4] &&
                                this.state.pageData.section[4].item[4].image)
                            }
                          />
                          <h4 className="black-title">
                            {this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[4]?.title[
                              this.props.lang
                              ]}{" "}
                          </h4>
                          <div
                            className="black-text"
                            dangerouslySetInnerHTML={{
                              __html: Object.translate(
                                this.state.pageData &&
                                this.state.pageData.section[4] &&
                                this.state.pageData.section[4].item[4],
                                "shortDescription",
                                this.props.lang
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-curoflow">
                    <div className="col-curoflow-lg-4 bg-white">
                      <div className="info-box">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[5] &&
                              this.state.pageData.section[4].item[5].image)
                          }
                        />
                        <h4 className="black-title">
                          {this.state.pageData &&
                            this.state.pageData.section[4] &&
                            this.state.pageData.section[4].item[5]?.title[
                            this.props.lang
                            ]}{" "}
                        </h4>
                        <div
                          className="black-text"
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[5],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-curoflow-lg-4 bg-white">
                      <div className="info-box">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[6] &&
                              this.state.pageData.section[4].item[6].image)
                          }
                        />
                        <h4 className="black-title">
                          {this.state.pageData &&
                            this.state.pageData.section[4] &&
                            this.state.pageData.section[4].item[6]?.title[
                            this.props.lang
                            ]}{" "}
                        </h4>
                        <div
                          className="black-text"
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[6],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-curoflow-lg-4 bg-white">
                      <div className="info-box">
                        <Isvg
                          src={
                            API_ENDPOINT +
                            (this.state.pageData &&
                              this.state.pageData.section[4] &&
                              this.state.pageData.section[4].item[7] &&
                              this.state.pageData.section[4].item[7].image)
                          }
                        />
                        <h4 className="black-title">
                          {this.state.pageData &&
                            this.state.pageData.section[3] &&
                            this.state.pageData.section[4].item[7]?.title[
                            this.props.lang
                            ]}{" "}
                        </h4>
                        <div
                          className="black-text"
                          dangerouslySetInnerHTML={{
                            __html: Object.translate(
                              this.state.pageData &&
                              this.state.pageData.section[3] &&
                              this.state.pageData.section[4].item[7],
                              "shortDescription",
                              this.props.lang
                            ),
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ) : null}
            {this.state.pageData.section6 ? <div></div> : null}
            <ContactForm
              title={
                this.state.pageData &&
                this.state.pageData.section[6] &&
                this.state.pageData.section[6].item[0]?.title[this.props.lang]
              }

              text={
                this.state.pageData &&
                  this.state.pageData.section[6] &&
                  this.state.pageData.section[6].item[0]
                  ? Object.translate(
                    this.state.pageData &&
                    this.state.pageData.section[6] &&
                    this.state.pageData.section[6].item[0],
                    "shortDescription",
                    this.props.lang
                  )
                  : null
              }
              contactFormError={this.state.contactFormError}
              contactFormMessageError={this.state.contactFormMessageError}
              class={"grey"}
              justShowForm={true}
              showTextContact={true}
              onSubmit={this.sendMessage2}
              lang={this.props.lang}
              initialValues={{
                dob: '1'
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default Page(OurSolutions);
