import React, { Component } from 'react';
import Page from '../containers/page';
import { API_ENDPOINT } from "../constants";
import { Player } from '@lottiefiles/react-lottie-player';
import CuroflowIcon from "../assets/images/Curoflow_Digital_Telemedicine_Platform_Icon.png"
import SupportForm from '../components/forms/supportForm';
// import ContactForm from '../components/forms/contactForm';
import AOS from 'aos';
import 'aos/dist/aos.css'
import Img from '../components/Img';
import ContactForm from '../components/forms/contactForm31';


var striptags = require('striptags');

function parseJSON(response) {
    return response.json().then((json) => {
      return {
        result: json,
        status: response.status,
      };
    });
  }

class CustomersTellUs extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.player = React.createRef(); 
        this.state = {
            promotedProjects: [],
            poolActive: false,
            chatForm: false,
            contactForm: false,
            yScroll2: 0,
            customersTellUs:[],
            ...props.initialData
        };
    }

    listenToScroll = () => {
        const yScroll2 =
            document.body.scrollTop || document.documentElement.scrollTop


        this.setState({
            yScroll2: yScroll2,
        })
    }

    sendMessage1 = (data) => {
        if(data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {
       
                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({data})   
                }).then(parseJSON).then(({result, status}) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    sendMessage45 = (data) => {
        this.props.setRecaptchaCallback(() => {

            if (data && data.checked && this.props.allowSubmit)  {
            if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1  || data.email.toLowerCase().indexOf('@admin.com') !== -1  || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
                return this.setState({ contactFormError18: 'Please fill in your work email' })
            } else this.setState({ contactFormError18: null })
            
            // if (data.message?.length < 25) return this.setState({ contactFormMessageError18: 'Please use at least 25 letters' });
            // else this.setState({ contactFormMessageError18: null })

            data.lang = this.props.lang
            data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
            data.bookADemo=false
            data.page=this.props.lang==="en"? "Customer tell us" : "Kunder berättar"
            data.token = this.props.recaptchaToken
            data.isMobile = this.props.isMobile
            data.osMobileNameUA = this.props.osMobileNameUA
            data. browserName = this.props.browserName,
            data.browserNameUA = this.props.browserNameUA
            this.setState({
                loading2: true
            }, () => {

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status === 429) {
                        this.setState({
                            contactStatus: true,
                            loading2: false
                        })
                    }
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        const path = 'thank-you';
                        this.props[0].history.push(`/${path}`);
                    }
                })
            })
        }
    })
    this.props.updateToken();

    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }
    
    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.updateWindowDimensions();
        window.addEventListener('scroll', this.listenToScroll)

        AOS.init({
            duration: 1000
        })

      this.get();
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }
    

    render() {
            console.log(this.state.customersTellUs);
        return (
            <div>

            <div className={this.state.contactForm ? 'contact-popup open' : 'contact-popup'}>
                        <span className="close-icon" onClick={() => {
                            this.setState({
                                contactForm: false
                            })
                        }}>x</span>
                {
                    !this.state.loading2 ? 
                    <ContactForm onSubmit={this.sendMessage2} lang={this.props.lang} />
                    :null
                }
                {
                     this.state.loading2 ? 
                    <Player
                        ref={this.player} 
                        autoplay={true}
                        onEvent={(event) => {
                            if(event == 'complete') {
                                this.setState({
                                    contactForm: false,
                                    loading2: false
                                })
                            }
                        }}
                        //keepLastFrame={true}
                        //loop={false}
                        controls={true}
                        src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                        style={{ height: '300px', width: '300px' }}
                    ></Player>
                    :
                    null
                }
                </div>
                <div className={this.props.openModalBookDemo  ? 'contact-popup open' : 'contact-popup'}>
                    <span className="close-icon" onClick={() => {
                         this.props.bookDemo(false)
                    }}>x</span>
                    {
                        !this.state.loading3 && !this.state.supportStatus?
                            <SupportForm onSubmit={this.sendMessage2} lang={this.props.lang}/>
                            : null
                    }
                    {
                        this.state.loading3 ?
                            <Player
                                ref={this.player}
                                autoplay={true}
                                onEvent={(event) => {
                                    if (event == 'complete') {
                                        this.setState({
                                            supportForm: false,
                                            loading3: false
                                        })
                                    }
                                }}
                                //keepLastFrame={true}
                                //loop={false}
                                controls={true}
                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                style={{ height: '300px', width: '300px' }}
                            ></Player>
                            :
                            null
                    }
                    {this.state.supportStatus && <h2 className='status-popup support-status'>{'Please, try again later!'.translate(this.props.lang)}</h2>}
                </div>


                    <div>
                        <section className='sectionTextBigimg sectionTextBigImgNoBg'>
                            <div className="title">
                                <h1>{this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[0] && this.state.customersTellUs.section[0].title[this.props.lang]} </h1>
                                <div dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[0] && this.state.customersTellUs.section[0], 'shortDescription', this.props.lang)}}></div>
                            </div>
                            <img src={CuroflowIcon} className="leftSide-customer" alt="Curoflow Digital Telemedicine Platform Icon"/>
                        </section>
                        <section className='curoflow-section-box  home-section-grey'>
                            {/* <h1 className='text-center'>{this.state.customersTellUs && this.state.customersTellUs[1]  && this.state.customersTellUs[1].item[0]?.title[this.props.lang]} </h1> */}
                            <h1 className='text-center'>{this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[1] && this.state.customersTellUs.section[1].item && this.state.customersTellUs.section[1].item[0] && this.state.customersTellUs.section[1].item[0]?.title[this.props.lang]} </h1>
                            <div className="container-curoflow customer-margin-top">
                                <div className="col-curoflow-lg-6 ">
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[1] && this.state.customersTellUs.section[1].item && this.state.customersTellUs.section[1].item[1], 'shortDescription', this.props.lang)}}></div>
                                   
                                </div>
                                <div className="col-curoflow-lg-6 ">
                                    <Img src={API_ENDPOINT + (this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[1] && this.state.customersTellUs.section[1].item && this.state.customersTellUs.section[1].item[2] &&  this.state.customersTellUs.section[1].item[2].image)}  className="img-margin1" alt={(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[1] && this.state.customersTellUs.section[1].item && this.state.customersTellUs.section[1].item[2] &&  this.state.customersTellUs.section[1].item[2].image?.replace('/uploads/', '')?.replace(/_/g, ' ')?.slice(0, -4))}/>
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[1] && this.state.customersTellUs.section[1].item && this.state.customersTellUs.section[1].item[2], 'shortDescription', this.props.lang)}}></div>
                                </div>
                            </div>
                        </section>
                        <section className='curoflow-section-box   new-customer-tell-us'>
                            {/* <h1 className='text-center'>{this.state.customersTellUs && this.state.customersTellUs[1]  && this.state.customersTellUs[1].item[0]?.title[this.props.lang]} </h1> */}
                            <h1 className='text-center'>{this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[7] && this.state.customersTellUs.section[7].item && this.state.customersTellUs.section[7].item[0] && this.state.customersTellUs.section[7].item[0]?.title[this.props.lang]} </h1>
                            <div className="container-curoflow customer-margin-top">
                                <div className="col-curoflow-lg-6 ">
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[7] && this.state.customersTellUs.section[7].item && this.state.customersTellUs.section[7].item[1], 'shortDescription', this.props.lang)}}></div>
                                   
                                </div>
                                <div className="col-curoflow-lg-6 ">
                                    <Img src={API_ENDPOINT + (this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[7] && this.state.customersTellUs.section[7].item && this.state.customersTellUs.section[7].item[2] &&  this.state.customersTellUs.section[7].item[2].image)}  className="img-margin1" alt={(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[7] && this.state.customersTellUs.section[7].item && this.state.customersTellUs.section[7].item[2] &&  this.state.customersTellUs.section[7].item[2].image?.replace('/uploads/', '')?.replace(/_/g, ' ')?.slice(0, -4))}/>
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[7] && this.state.customersTellUs.section[7].item && this.state.customersTellUs.section[7].item[2], 'shortDescription', this.props.lang)}}></div>
                                </div>
                            </div>
                        </section>
                        <section className='curoflow-section curoflow-paddiing home-section-blue new-customer-tell-us-blue'>
                            <div className=' text-center'>
                            
                                <h1>{this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[2] && this.state.customersTellUs.section[2].item && this.state.customersTellUs.section[2].item[0].title &&  this.state.customersTellUs.section[2].item[0].title[this.props.lang]} </h1>
                            </div>
                          
                            <div className="container-curoflow customer-margin-top">
                                <div className="col-curoflow-lg-6 ">
                                
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[2] && this.state.customersTellUs.section[2].item && this.state.customersTellUs.section[2].item[1], 'shortDescription', this.props.lang)}}></div>
                                   
                                </div>
                                <div className="col-curoflow-lg-6 ">
                                
                                    <Img src={API_ENDPOINT + (this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[2] && this.state.customersTellUs.section[2].item && this.state.customersTellUs.section[2].item[2] && this.state.customersTellUs.section[2].item[2].image)}  className="img-margin1" alt={(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[2] && this.state.customersTellUs.section[2].item && this.state.customersTellUs.section[2].item[2] && this.state.customersTellUs.section[2].item[2].image?.replace('/uploads/', '')?.replace(/_/g, ' ')?.slice(0, -4))}/>
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[2] && this.state.customersTellUs.section[2].item && this.state.customersTellUs.section[2].item[2] , 'shortDescription', this.props.lang)}}></div>
                                </div>
                            </div>
                        </section>

                        {/**nova sekcija 4 */}

                        <section className='curoflow-section-box  new-customer-tell-us-4'>
                            <div className='text-center'>
                            
                                <h1>{this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[3] && this.state.customersTellUs.section[3].item && this.state.customersTellUs.section[3].item[0].title &&  this.state.customersTellUs.section[3].item[0].title[this.props.lang]} </h1>
                            </div>
                          
                            <div className="container-curoflow customer-margin-top">
                                <div className="col-curoflow-lg-6 ">
                                
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[3] && this.state.customersTellUs.section[3].item && this.state.customersTellUs.section[3].item[1], 'shortDescription', this.props.lang)}}></div>
                                   
                                </div>
                                <div className="col-curoflow-lg-6 ">
                                
                                    <Img src={API_ENDPOINT + (this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[3] && this.state.customersTellUs.section[3].item && this.state.customersTellUs.section[3].item[2] && this.state.customersTellUs.section[3].item[2].image)}  className="img-margin1" alt={(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[3] && this.state.customersTellUs.section[3].item && this.state.customersTellUs.section[3].item[2] && this.state.customersTellUs.section[3].item[2].image?.replace('/uploads/', '')?.replace(/_/g, ' ')?.slice(0, -4))}/>
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[3] && this.state.customersTellUs.section[3].item && this.state.customersTellUs.section[3].item[2] , 'shortDescription', this.props.lang)}}></div>
                                </div>
                            </div>
                        </section>

                          {/**kraj nove sekcija 4 */}

                          
                        {/**nova sekcija 5 */}

                        <section className='curoflow-section curoflow-paddiing  customer-tell-us'>
                            <div className='color-black text-center'>
                            
                                <h1>{this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[4] && this.state.customersTellUs.section[4].item && this.state.customersTellUs.section[4].item[0].title &&  this.state.customersTellUs.section[4].item[0].title[this.props.lang]} </h1>
                            </div>
                          
                            <div className="container-curoflow customer-margin-top">
                                <div className="col-curoflow-lg-6 ">
                                
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[4] && this.state.customersTellUs.section[4].item && this.state.customersTellUs.section[4].item[1], 'shortDescription', this.props.lang)}}></div>
                                   
                                </div>
                                <div className="col-curoflow-lg-6 ">
                                
                                    <Img src={API_ENDPOINT + (this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[4] && this.state.customersTellUs.section[4].item && this.state.customersTellUs.section[4].item[2] && this.state.customersTellUs.section[4].item[2].image)}  className="img-margin1" alt={(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[4] && this.state.customersTellUs.section[4].item && this.state.customersTellUs.section[4].item[2] && this.state.customersTellUs.section[4].item[2].image?.replace('/uploads/', '')?.replace(/_/g, ' ')?.slice(0, -4))}/>
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[4] && this.state.customersTellUs.section[4].item && this.state.customersTellUs.section[4].item[2] , 'shortDescription', this.props.lang)}}></div>
                                </div>
                            </div>
                        </section>

                          {/**kraj nove sekcija 5 */}

                             {/**nova sekcija 6 */}

                          <section className='curoflow-section-box  home-section-grey1 home-section-grey'>
                            {/* <h1 className='text-center'>{this.state.customersTellUs && this.state.customersTellUs[1]  && this.state.customersTellUs[1].item[0]?.title[this.props.lang]} </h1> */}
                            <h1 className='text-center'>{this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[6] && this.state.customersTellUs.section[6].item && this.state.customersTellUs.section[6].item[0] && this.state.customersTellUs.section[6].item[0]?.title[this.props.lang]} </h1>
                            <div className="container-curoflow customer-margin-top">
                                <div className="col-curoflow-lg-6 ">
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[6] && this.state.customersTellUs.section[6].item && this.state.customersTellUs.section[6].item[1], 'shortDescription', this.props.lang)}}></div>
                                   
                                </div>
                                <div className="col-curoflow-lg-6 ">
                                    <Img src={API_ENDPOINT + (this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[6] && this.state.customersTellUs.section[6].item && this.state.customersTellUs.section[6].item[2] &&  this.state.customersTellUs.section[6].item[2].image)}  className="img-margin1" alt={(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[6] && this.state.customersTellUs.section[6].item && this.state.customersTellUs.section[6].item[2] &&  this.state.customersTellUs.section[6].item[2].image?.replace('/uploads/', '')?.replace(/_/g, ' ')?.slice(0, -4))}/>
                                    <div className='customer-section2' dangerouslySetInnerHTML={{__html: Object.translate(this.state.customersTellUs && this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[6] && this.state.customersTellUs.section[6].item && this.state.customersTellUs.section[6].item[2], 'shortDescription', this.props.lang)}}></div>
                                </div>
                            </div>
                        </section>

                            {/**kraj nove sekcija 6 */}

                    <ContactForm   title={this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[5] && this.state.customersTellUs.section[5].item[0] && this.state.customersTellUs.section[5].item[0].title && this.state.customersTellUs.section[5].item[0].title[this.props.lang]} text={this.state.customersTellUs && this.state.customersTellUs.section && this.state.customersTellUs.section[5] && this.state.customersTellUs.section[5].item[1] && this.state.customersTellUs.section[5].item[1].shortDescription && this.state.customersTellUs.section[5].item[1].shortDescription[this.props.lang]} class={'grey'} contactFormError={this.state.contactFormError18} lang={this.props.lang} justShowForm = {true}  onSubmit={this.sendMessage45}  showTextContact={true} contactPage={true} contactFormMessageError={this.state.contactFormMessageError18}/>

                    </div>

                   

                    <div className={this.state.contactForm ? 'overlay overlay-active' : 'overlay'} onClick={() => {
                        this.setState({
                            contactForm: false
                        })
                    }}></div>

            </div>
        );
    }
}

export default Page(CustomersTellUs);

