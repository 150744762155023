import React, { Component } from 'react';
import Page from '../containers/page';
import Article from '../components/article';
import { API_ENDPOINT } from "../constants";
import CuroflowIcon from "../assets/images/Curoflow_Digital_Telemedicine_Platform_Icon.png"
import SupportForm from '../components/forms/supportForm1';
import chat from "../assets/images/chat.png";
import ChatForm from "../components/forms/chatForm10";

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input
} from 'reactstrap';


var striptags = require('striptags');
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';

class NewsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            poolActive: false,
            totalArticles: 0,
            chatForm: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            ...props.initialData
        };
    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        this.get()

    }

    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0})).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0})).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }


    }


    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };

                obj[name] = value;

                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;


                this.setState({ params: obj })
            }

            this.setState({
                _useStateTs: new Date().getTime()
            })
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
        this.get()
    }

    sendMessage1 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading1: true
            }, () => {

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                    }
                })
            })
        }
    }

    sendMessage2 = (data) => {
        if (data.checked) {
            data.lang = this.props.lang
            this.setState({
                loading2: true
            }, () => {

                setTimeout(() => {
                    this.player.current.pause()
                }, 1120)

                fetch(API_ENDPOINT + '/data/contact/new', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ data })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status === 429) {
                        this.setState({
                            solutionsStatus: true,
                            loading2: false
                        })
                    }
                    if (result.error) {
                        this.setState({ error: result.error })
                    } else {
                        this.props.bookDemo(false)

                        const path = 'thank-you'.translate(this.props.lang);
                        this.props[0].history.push(`/${path}`);
                    }
                })
            })
        }
    }



    // get = () => {
    //     fetch(API_ENDPOINT + '/data/pages/all-news', {
    //         method: 'POST',
    //         headers: {
    //             'content-type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             page: this.state.page,
    //             entries: this.state.entries,
    //         })
    //     }).then(res => res.json()).then((data) => {
    //         this.setState({
    //             articles: data.items,
    //             totalArticles: data.total
    //         })
    //     })
    // }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0})
        }
      
      
        return (
            <div>
             

<div className={this.props.openModalBookDemo  ? 'contact-popup open' : 'contact-popup'}>
                    <span className="close-icon" onClick={() => {
                         this.props.bookDemo(false)
                    }}>x</span>
                    {
                        !this.state.loading3 && !this.state.supportStatus?
                              <SupportForm onSubmit={this.sendMessage2} lang={this.props.lang} />
                            : null
                    }
                    {
                        this.state.loading3 ?
                            <Player
                                ref={this.player}
                                autoplay={true}
                                onEvent={(event) => {
                                    if (event == 'complete') {
                                        this.setState({
                                            supportForm: false,
                                            loading3: false
                                        })
                                    }
                                }}
                                //keepLastFrame={true}
                                //loop={false}
                                controls={true}
                                src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
                                style={{ height: '300px', width: '300px' }}
                            ></Player>
                            :
                            null
                    }
                    {this.state.supportStatus && <h2 className='status-popup support-status'>{'Please, try again later!'.translate(this.props.lang)}</h2>}
                </div>


                <div className="news-page">
                    <section className="sectionTextBigimg sectionTextBigImgNoBg">
                        <div className='title'>
                            <h1>{'News'.translate(this.props.lang)}</h1>
                            <h6>{"Here we collect press releases and major news about Curoflow. See also our".translate(this.props.lang)} <a href="https://www.linkedin.com/company/18290458/" target="_blank">LinkedIn</a> {"for more news".translate(this.props.lang)}.</h6>
                        </div>
                        <img src={CuroflowIcon} className="leftSide-customer" alt="Curoflow Digital Telemedicine Platform Icon" />
                    </section>
                    <div className="container-curoflow">

                        {
                            this.state.articles && this.state.articles.map((article, idx) => {
                                return (
                                    <div className="col-curoflow-news-lg-6">
                                        <Article
                                            id={article._id}
                                            backgroundImage={article.backgroundImage}
                                            title={article.title && article.title[this.props.lang]}
                                            date={article.date}
                                            shortDescriptionPage={article.shortDescriptionPage && article.shortDescriptionPage[this.props.lang]}
                                            description={article.description && article.description[this.props.lang]}
                                            tsCreated={article.tsCreated}
                                            address = {article.address}
                                            buttonText={'Read more'.translate(this.props.lang)}
                                            article={article}
                                            lang={this.props.lang}
                                        />

                                    </div>
                                )
                            })
                        }

                    </div>
                    <div className='container-curoflow curoflow-margin-bottom'>
                        <div className='pagination-news'>


                            <button onClick={() => {
                                if (this.state.params.page > 0)
                                    this.setState({
                                        page: this.state.params.page - 1
                                    }, () => {
                                        this.updateParams('page',this.state.page)
                                        window.scrollTo(0, 0);
                                    })
                            }}>{'Previous'.translate(this.props.lang)}</button>
                            <div>{'Pages:'} {this.state.params.page + 1} / {Math.ceil(this.state.totalArticles / 10)}</div>
                            <button onClick={() => {
                                if (this.state.params.page + 1 < Math.ceil(this.state.totalArticles / 10))
                                    this.setState({
                                        page: this.state.params.page + 1
                                    }, () => {
                                        this.updateParams('page',this.state.page)
                                        // this.get()
                                        window.scrollTo(0, 0);
                                    })
                            }}>{'Next'.translate(this.props.lang)}</button>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Page(NewsPage);

